<template>
  <div class="container">
    <div class="content">
      <transition name="move">
        <div class="item" ref="">
          <span>
            蒙上了灰的夏夜，<br />亦有星辰在我心中，<br />平凡的你像黑洞始终牵引着我的心。
          </span>
        </div>
      </transition>
      <div class="item">
        <a href="https://blog.wuyan.icu/Pic" target="_blank">照片墙</a>
        <a href="https://blog.wuyan.icu/Sentence" target="_blank">句格</a>
        <a href="https://blog.wuyan.icu/Comment" target="_blank">留言墙</a>
        <a href="https://blog.wuyan.icu/TimeLine" target="_blank">时间线</a>
        <a href="https://blog.wuyan.icu/Video" target="_blank">留声机</a>
        <a href="https://blog.wuyan.icu" target="_blank">...</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "newIndex",
  data() {
    return {
      sentenceList: [
        "蒙上了灰的夏夜，<br />亦有星辰在我心中，<br />平凡的你像黑洞始终牵引着我的心。",
        "粉笔的气味，<br />各处布满参差裂痕的玻璃窗，<br />远处传来的运动的声音，夕阳投出的片片樱瓣倩影",
        `"莫扎特曾经说过大胆地踏上旅途吧，<br />我不知道路途的前方究竟有什么，<br />但是，我们还是迈出了步伐，我们仍在旅途之中"`,
      ],
      index: 0,
    };
  },
  mounted() {
    setInterval(() => {
      let target = document.querySelector(".item");
      if (this.index === 0) {
        target.innerHTML = this.sentenceList[this.index];
        this.index++;
      } else if (this.index === 1) {
        target.innerHTML = this.sentenceList[this.index];
        this.index++;
      } else if (this.index === 2) {
        target.innerHTML = this.sentenceList[this.index];
        this.index++;
      } else {
        this.index = 0;
      }
    }, 4000);
  },
};
</script>
<style scoped lang="less">
@media screen and (min-width: 1180px) {
  .container {
    width: 100vw;
    height: 100vh;
    background-color: #960b19;
    .content {
      height: 100%;
      align-items: center;
      color: #ffd1d1;
      font-family: HarmonyOS;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item {
        font-size: 40px;
        font-weight: 600;
        width: 900px;
        min-width: 900px;
        span {
          height: 500px;
        }
        a {
          font-size: 30px;
          text-decoration: none;
          color: #e57571;
          margin-top: 30px;
          margin-right: 20px;
          transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        a:hover {
          color: #ffd1d1;
          border-bottom: 3px solid #e57571;
        }
      }
    }
  }
}
@media screen and (min-width: 820px) and (max-width: 1180px) {
  .container {
    width: 100vw;
    height: 100vh;
    background-color: #960b19;
    .content {
      height: 100%;
      align-items: center;
      color: #ffd1d1;
      font-family: HarmonyOS;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item {
        font-size: 40px;
        font-weight: 600;
        width: 900px;
        min-width: 900px;
        span {
          height: 500px;
        }
        a {
          font-size: 30px;
          text-decoration: none;
          color: #e57571;
          margin-top: 30px;
          margin-right: 20px;
          transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        a:hover {
          color: #ffd1d1;
          border-bottom: 3px solid #e57571;
        }
      }
    }
  }
}
@media screen and (min-width: 420px) and (max-width: 820px) {
  .container {
    width: 100vw;
    height: 100vh;
    background-color: #960b19;
    .content {
      height: 100%;
      align-items: center;
      color: #ffd1d1;
      font-family: HarmonyOS;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item {
        font-size: 40px;
        font-weight: 600;
        width: 900px;
        span {
          height: 500px;
        }
        a {
          font-size: 30px;
          text-decoration: none;
          color: #e57571;
          margin-top: 30px;
          margin-right: 20px;
          transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
        a:hover {
          color: #ffd1d1;
          border-bottom: 3px solid #e57571;
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .container {
    width: 100vw;
    height: 100vh;
    background-color: #960b19;
    .content {
      height: 100%;
      align-items: center;
      color: #ffd1d1;
      font-family: HarmonyOS;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .item {
        font-size: 20px;
        font-weight: 600;
        width: 320px;
        span {
          height: 500px;
        }
        a {
          font-size: 20px;
          text-decoration: none;
          color: #e57571;
          margin-top: 30px;
          margin-right: 10px;
          transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }
    }
  }
}
</style>