<template>
  <div id="app">
    <Index />
  </div>
</template>

<script>
import Index from "./components/newIndex.vue";
export default {
  name: "App",
  components: { Index },
};
</script>

<style>
@import url("./assets/font.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
